$black-dark: #212121;
$blue: #0066b2;
$blue-dark: #0b2d71;
$blue-light: #009dd9;
$blue-low-light: #f4fbfe;
$brown: #ee602d;
$cyan: #00708a;
$gray: #c8c8c8;
$gray-white: #f7f7f7;
$gray-extra-light: #f4f3f2;
$gray-light: #ededee;
$gray-dark: #6b6d6f;
$gray-primary: #8c8f93;
$orange: #da9c36;
$red: #e21836;
$white: #fff;
$status-complete: #2fd135;
$status-new: #00708c;
$separator-color: #cccccc;
$scrollbar-height: 18px;
$page-min-width: 1024px;
$page-min-height: 400px;
$page-header-height: 80px;
$page-sidebar-width: 150px;
$page-actions-panel-height: 56px;
$header-separator-height: 2px;
