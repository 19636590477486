/* You can add global styles to this file, and also import other style files */
@import './app/styles/reset.css';
@import './app/styles/fonts.scss';
@import './app/styles/text.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './../src/app/styles/variables.scss';
@import './app/styles/mixins.scss';
@import './app/styles/dialogs.scss';

body {
  @include iconf-text-GothamNarrowBook('normal');

  font-weight: 400;
  line-height: 1.5;
}

.error-snackbar {
  background-color: #eb4034;
}

.success-snackbar {
  background-color: #0097a7;
}

/* scrollbars for non-webkit browsers */
html {
  scrollbar-color: $blue transparent;
  overflow-y: auto;
}

* {
  scrollbar-width: thin;
}

/* scrollbars for webkit browsers */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($blue, 0.2);
  border-radius: 5px;
  &:hover {
    background: $blue;
  }
}

.iconf-btn-blank {
  @include iconf-btn-blank;
}

.iconf-btn-filled {
  @include iconf-btn-filled;
}

.iconf-btn-warning {
  @include iconf-btn-warning;
}

.iconf-select {  
  .mat-select-arrow-wrapper {
    @include iconf-select-arrow;
  }

  .mat-select-arrow {
    opacity: 0;
  }

  .mat-select-placeholder {
    @include iconf-select-empty;
  }

  .mat-select-value-text {
    @include iconf-select-filled;
  }

  &.mat-form-field {
    width: 100%;
    @include iconf-text-GothamNarrowBook('normal');
    line-height: 24px;
  }

  .mat-form-field-outline {
    background-color: white;
  }

  .mat-form-field-outline-start {
    @include iconf-corner;
  }

  .mat-form-field-outline-end {
    @include iconf-corner;
  }

  &.mat-form-field-appearance-outline {
    &:not(.mat-focused):not(.mat-form-field-invalid) .mat-form-field-outline {
      border: 2px solid $gray;
      @include iconf-select-border;
    }

    &:not(.mat-focused) .mat-form-field-flex:hover .mat-form-field-outline {
      border: 2px solid black;
      @include iconf-select-border;
    }

    &.mat-focused .mat-form-field-outline {
      @include iconf-select-active;
    }

    &.mat-form-field-invalid .mat-form-field-outline {
      @include iconf-select-invalid;
    }

    .mat-form-field-infix {
      padding: 7px 0 7px 0;
    }

    .mat-form-field-wrapper {
      margin: 0 0;
    }

    .mat-form-field-flex {
      margin-top: 0;
      padding: 0 7px 0 7px;
    }

    .mat-form-field-outline {
      top: 0;
    }

    .mat-select-arrow-wrapper {
      transform: unset;
    }
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  
}
.topunit.mat-select-panel
{
  background-color: white;

}

.iconf-tabs {
  &.tabs {
    display: flex;
    margin: 0;
    padding: 0;
  }

  a.tab-link {
    @include iconf-text-GothamBookMedium('small');
    display: inline-block;
    text-decoration: none;
    border: 0px;
    color: $gray;
    padding: 8px;
    padding-bottom: 4px;
    margin: 0;
    border-bottom: 4px solid transparent;
    &:link,
    &:visited {
    }
    &:hover {
      color: $blue;
    }
    &:active,
    &.active {
      color: $blue-dark;
      border-bottom-color: $blue-dark;
    }
  }

  .tab {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
