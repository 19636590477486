@import '/src/app/styles/variables.scss';

$dialog-padding: 40px;

@mixin dialog-bordered {
  border: solid 1px black;
  border-radius: 0px;
}

@mixin dialog-fit-screen {
  max-width: calc(100vw - 2 * #{$dialog-padding});
  max-height: calc(100vh - 2 * #{$dialog-padding});
}

@mixin iconf-dialog {
  @include dialog-bordered();
  @include dialog-fit-screen();
  position: relative;
}

.new-project-dialog-config .mat-dialog-container {
  width: 1000px;
  height: 800px;
  background-color: white;
}

.new-project-dialog-config .mat-dialog-content {
  max-height: initial;
}

.add-edit-facility-dialog .mat-dialog-container {
  width: 600px;
  height: 400px;
  background-color: white;
}

.add-edit-facility-dialog .mat-dialog-content {
  max-height: initial;
  height: 100%;
}

.edit-project-dialog .mat-dialog-container {
  width: 800px;
  height: 600px;
  background-color: white;
}

.add-edit-team-dialog .mat-dialog-container {
  width: 800px;
  height: 800px;
  background-color: white;
}

.new-project-dialog-config,
.add-edit-facility-dialog,
.edit-project-dialog,
.add-edit-team-dialog,
.delete-dialog {
  .mat-dialog-container {
    @include iconf-dialog();
  }
}
