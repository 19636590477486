@mixin iconf-text-base($size) {
  font-weight: normal;

  @if type-of($size) == number {
    font-size: $size + px;
  }

  @if $size == 'huge' {
    font-size: 30px;
  }

  @if $size == 'big' {
    font-size: 24px;
  }

  @if $size == 'normal' {
    font-size: 16px;
  }

  @if $size == 'small' {
    font-size: 12px;
  }
}

@mixin iconf-text-GothamBookMedium($font-size) {
  @include iconf-text-base($font-size);
  font-family: GothamBookMedium, sans-serif;
}

@mixin iconf-text-GothamBold($font-size) {
  @include iconf-text-base($font-size);
  font-family: GothamBold, sans-serif;
}

@mixin iconf-text-GothamNarrowBook($font-size) {
  @include iconf-text-base($font-size);
  font-family: GothamNarrowBook, sans-serif;
}

@mixin iconf-text-GothamBook($font-size) {
  @include iconf-text-base($font-size);
  font-family: GothamBook, sans-serif;
}

@mixin iconf-btn-base {
  @include iconf-text-GothamNarrowBook('normal');
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;

  &.minw250 {
    min-width: 250px;
  }

  &.minw200 {
    min-width: 200px;
  }

  &.minw150 {
    min-width: 150px;
  }

  &.minw100 {
    min-width: 100px;
  }

  &.minw50 {
    min-width: 50px;
  }

  &.minh40 {
    min-height: 40px;
  }

  &:focus {
    outline: none;
  }

  & mat-icon {
    vertical-align: middle;
  }
}

@mixin iconf-btn-filled {
  @include iconf-btn-base();

  color: $white;
  background-color: $blue;

  &:hover {
    background-color: $blue-dark;
  }

  @include iconf-btn-disabled($gray, $white);
}

@mixin iconf-btn-blank {
  @include iconf-btn-base();

  background-color: transparent;
  color: $blue;

  &.active {
    color: $blue-dark;
    background-color: #f7f7f7;
  }

  &.margin-border {
    border: 2px solid $blue;
    margin: 5px 15px 5px 0;
  }

  &.selected {
    color: $white;
    background-color: $blue;
  }

  @include iconf-btn-disabled(transparent, $gray);
}
@mixin iconf-btn-warning {
  @include iconf-btn-base();

  background-color: $red;
  color: $white;
}

@mixin iconf-btn-disabled($btn-background-color, $btn-font-color) {
  &:disabled {
    cursor: default;
    pointer-events: none;
    background-color: $btn-background-color;
    color: $btn-font-color;
  }
}

@mixin iconf-corner {
  border-radius: 0px 0 0 0px !important;
}

@mixin iconf-select-empty {
  color: $gray-dark;
}

@mixin iconf-select-arrow {
  background-image: url('../../assets/images/chevron-down.svg');
}

@mixin iconf-select-filled {
  color: black;
}

@mixin iconf-select-border {
  opacity: 1;
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border: none;
  }
}

@mixin iconf-select-invalid {
  color: $red;
}

@mixin iconf-select-active {
  color: $blue;
}

@mixin iconf-form-input {
  padding: 5px;
  border: 2px solid $gray;
  color: $black-dark;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;

  &[disabled] {
    border-color: $gray;
    background-color: $white;
    color: $gray-primary;
  }

  &:focus {
    border-color: $blue;
  }

  &.warning {
    border-color: $orange;
  }

  &.ng-dirty.ng-invalid,
  &.invalid {
    border-color: $red;
  }

  &::placeholder {
    color: $gray-dark;
  }
}

@mixin iconf-form-textarea {
  @include iconf-form-input;
}

// legacy

@mixin radio($radio-checked-img, $radio-empty-img) {
  position: relative;
  padding-left: 26px;
  /* Base for label styling */
  [type='radio']:not(:checked),
  [type='radio']:checked {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    color: $black-dark;
  }
  /* radio circle */
  [type='radio']:not(:checked) + span:before,
  [type='radio']:checked + span:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: 1px solid $blue-light;
    border-radius: 18px;
  }

  [type='radio'] + span {
    cursor: pointer;

    &:before {
      background-size: cover;
    }
  }
  [type='radio']:checked + span:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: $blue-light;
    border: 1px solid $blue-light;
  }
  /* disabled radio */
  [type='radio']:disabled:not(:checked) + span:before,
  [type='radio']:disabled:checked + span:before {
    cursor: default;
    pointer-events: none;
  }

  [type='radio']:disabled:not(:checked) + span:after {
    opacity: 0;
  }

  [type='radio']:disabled:checked + span:after {
    opacity: 0.5;
  }
  /* disabled radio label */
  [type='radio']:disabled + span {
    color: $gray;
    cursor: default;
    pointer-events: none;
  }
}

@mixin form-input {
  height: 35px;
  @include iconf-text-GothamNarrowBook('normal');
  padding: 5px;
  border: 2px solid $blue-light;
  color: $black-dark;
  outline: none;

  &[disabled] {
    border-color: $gray;
    background-color: $white;
  }

  &.out-of-bounds {
    border-color: $orange;
  }

  &.invalid {
    border-color: $red;
  }

  &:-ms-input-placeholder {
    color: $gray;
  }

  &::placeholder {
    color: $gray;
  }
}

@mixin checkbox($color, $bgrndColor, $borderColor, $hoverColor) {
  position: relative;
  padding-left: 26px;
  /* Base for label styling */
  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  [type='checkbox'] + span {
    cursor: pointer;
  }
  /* checkbox aspect */
  [type='checkbox']:not(:checked) + span:before,
  [type='checkbox']:checked + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $borderColor;
    background-color: $bgrndColor;
    border-radius: 2px;
    cursor: pointer;
  }
  /* checked mark aspect */
  [type='checkbox']:not(:checked) + span:after,
  [type='checkbox']:checked + span:after {
    content: '✔';
    position: absolute;
    top: 0.2em;
    left: 0.25em;
    line-height: 0.8;
    color: $color;
    cursor: pointer;
  }
  /* checked mark aspect changes */
  [type='checkbox']:not(:checked) + span:after {
    opacity: 0;
  }

  [type='checkbox']:checked + span:after {
    opacity: 1;
  }
  /* disabled checkbox */
  [type='checkbox']:disabled + span {
    color: $gray;
    cursor: default;
    pointer-events: none;
  }

  [type='checkbox']:disabled + span:before,
  [type='checkbox']:disabled + span:after {
    cursor: default;
    pointer-events: none;
  }

  [type='checkbox']:disabled:not(:checked) + span:after {
    opacity: 0;
  }

  [type='checkbox']:disabled:checked + span:after {
    opacity: 0.5;
  }

  span:hover:before {
    background-color: $hoverColor !important;
  }
}

/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
@mixin page-footer {
  position: relative;
  height: $page-actions-panel-height;
  background-color: $blue-low-light;
  padding: 8px 30px;
}

@mixin page-content {
  min-height: 100px;
  height: calc(100% - #{$page-actions-panel-height});
  overflow: auto;
  padding: 20px 30px;

  @media screen and (max-height: $page-min-height) {
    overflow: inherit;
  }
}

@mixin page-body-with-sidebar {
  width: calc(100% - #{$page-sidebar-width});
  height: calc(100vh - #{$page-header-height});

  @media screen and (max-width: $page-min-width) {
    height: calc(100vh - #{$page-header-height} - #{$scrollbar-height});
  }

  @media screen and (max-height: $page-min-height) {
    height: 100%;
  }

  overflow: auto;
  padding: 20px 30px;
}

/*
  It applies to the title (header) of page content body.
*/
@mixin page-content-header {
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
  color: $black-dark;
  margin: 10px 0 30px;
}

@mixin page-content-subtitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: $black-dark;
  margin: 6px 0 20px;
}

@mixin sidebar-button-bottom {
  margin: 50px $page-sidebar-padding 8px;
}

@mixin sidebar-button-blue-dark {
  @include button;
  background-color: $blue-dark;
  border: 2px solid $white;
}

/*
  It describes common behavior on mouse hover.
*/
@mixin hover {
  &:hover {
    opacity: 0.8;

    @content;
  }
}

/*
  It describes light shadow for any page block.
*/
@mixin light-shadow {
  -webkit-box-shadow: 0px 5px 20px -4px $gray-dark;
  box-shadow: 0px 5px 20px -4px $gray-dark;
}

/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
@mixin entity-card {
  font-size: 13px;
  margin-bottom: 30px;
  margin-right: 20px;
  @include light-shadow;
}

/*
  It applies together with entity-card mixin, which has been described above
*/
@mixin entity-card-header {
  height: 50px;
  color: $white;
  vertical-align: middle;
  font-size: 18px;
}

/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
@mixin entity-card-header-action-btn($btnWidth) {
  width: $btnWidth;
  float: right;
  background-color: $blue;
  border-left: 2px solid transparent;
  cursor: pointer;
  text-align: center;
  height: 100%;
  line-height: 50px;
}

/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
@mixin entity-card-header-title($btnWidth) {
  margin-right: calc(#{$btnWidth} + #{$header-separator-height});
  padding: 0 20px;
  background-color: $blue;
  height: 100%;
  line-height: 50px;
}
