@import './variables';
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $blue-dark;
  font-family: 'GothamBold', Arial, sans-serif;
  font-weight: normal;
  margin-bottom: 15px;
  text-align: center;
}
h1 {
  font-size: 32px;
  line-height: 38px;
}
h2 {
  font-size: 26px;
  line-height: 30px;
}
h3 {
  font-size: 20px;
  line-height: 26px;
}
h4 {
  font-size: 18px;
  line-height: 22px;
}
h5 {
  font-size: 14px;
  line-height: 20px;
}
h6 {
  font-size: 12px;
  line-height: 16px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
